
.projImg {
  border: 3px solid darkcyan;
  margin-top: 10px;
}

.techStack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.techStack > h1 {
  font-size: 1.4em;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr; 
  text-align: center;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: whitesmoke;
  min-height: fit-content;
  margin-bottom: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.gridContainer3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: fit-content;
  background-color: #181819;
  margin-top: 40px;
  margin-bottom: 40px;
}

.one {
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  margin: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  padding: 12px;
  height: fit-content;
}

.one > img {
  height: 70%;
  width: 50%;
}

.one > h2, h1 {
  font-size: .8em;
  text-shadow: none;
}

.detailsBtn {
  background-color: darkcyan;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  color: whitesmoke;
  font-size: medium;
  font-family: costa, sans-serif;
  width: 160px;
  margin-bottom: 10px;
}

.detailsBtn:hover {
  box-shadow: #a84444 0px 10px 40px -10px, #a84444 0px 10px 10px -10px,#a84444 0px -2px 6px 0px inset;
  border: 1px solid #a84444;
  color: #a84444;
}

.languages2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  background-color: whitesmoke;
  width: 100%;
  padding: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.svg {
  height: 4vh;
  fill:#a84444;
  margin: 5px;
}

.detailsDiv {
  background-color: whitesmoke;
  min-height: 45vh;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  padding: 30px;
  padding-bottom: 40px;
  border: 2px solid darkcyan;
}

.linkDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: darkcyan;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  width: 55%;
  padding: 4px;
  margin-top: 10px;
  font-family: costa, sans-serif;
}

.linkDiv:hover {
  box-shadow: #a84444 0px 10px 40px -10px, #a84444 0px 10px 10px -10px,#a84444 0px -2px 6px 0px inset;
  border: 1px solid #a84444;
  color: #a84444;
}

.play {
  color: whitesmoke;
}

.play:hover {
  color: #a84444;
}

@media only screen and (min-width: 568px) {
  .one > img {
    height: 14vh;
    width: 125px;
  }
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 1.4em;
  }
  h3 {
    font-size: 1.2em;
  }
  .one > img {
    height: 18vh;
    width: 165px;
  }
  .gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    height: fit-content;
  }

  .one {
    height: 30vh;
  }
}
