
h1 {
  line-height: 1em;
  font-family: costa, sans-serif;
  font-style: normal;
}

h2 {
  font-family: costa, sans-serif;
  font-size: 1em;
  font-weight: bolder;
  font-style: italic;
}

h3 {
  color: #181819;
  line-height: 2em;
  font-family: costa, sans-serif;
}

.one1 {
  font-family: costa, sans-serif;
}

.icons {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: whitesmoke;
}

.gridContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: #181819;
  z-index: -1;
}

.gridContainer > h1 {
  font-size: 1.4em;
}

.one1 {
  text-align: left;
  background-color: #181819;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  z-index: -1;
  width: 100%;
}

.resume {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resume > a {
  background-color: darkcyan;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  padding: 15px;
  height: 15px;
  width: 200px;
  margin-bottom: 40px;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  font-family: costa, sans-serif;
}

.resume > a:hover {
  box-shadow: #a84444 0px 10px 40px -10px, #a84444 0px 10px 10px -10px,#a84444 0px -2px 6px 0px inset;
  border: 1px solid #a84444;
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 2em;
  }
  h3 {
    font-size: 1.2em;
  }

  .gridContainer {
    width: 70%;
  }
}

