header {
  width: 100%;
}

a {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: whitesmoke;
  font-weight: bold;
}

a:hover {
  color: #a84444;
}

.menuNav {
  display: none;
}

.menuNav.showMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  top: 0;
  height: 35px;
  width: 100%;
  list-style: none;
  background: #181819;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: .75em;
}

.menuNav.showMenu > img {
  width: 25px;
}

#linkedin-icon, #github-icon, #camera-icon {
  height: 2.8vh;
  margin-top: 10px;
}

#brand-image {
  margin-top: 10px;
}

#icon-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  width: 100%;
  padding-bottom: 15px;
  bottom: 0;
  background: #181819;
}

#lottie-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}