.introDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: translateY(-75px);
  z-index: 1;
  font-family: costa, sans-serif;
}

.name > h1 {
  font-size: 2.3em;
  text-shadow: -1px 1px whitesmoke;
  color: #a84444;
}

.name > h2 {
  font-size: .8em;
  color: whitesmoke;
  margin-top: 72px;
}

.name > h3 {
  color: whitesmoke;
  font-size: .6em;
  margin-top: -78px;
}

@media only screen and (min-width: 768px) {

  .name > h1 {
    font-size: 3.8em;
    text-shadow: -1px 1px whitesmoke;
    color: #a84444;
  }

  .name > h3 {
    color: whitesmoke;
    margin-top: -125px;
    font-size: .8em;
  }

  .name > h2 {
    color: whitesmoke;
    margin-top: 100px;
  }

  .dino {
    margin-top: 12px;
  }
}

@media only screen and (min-width: 1100px) {

  .name > h1 {
    font-size: 5.8em;
    text-shadow: -1px 1px whitesmoke;
    color: #a84444;
  }

  .name > h3 {
    color: whitesmoke;
    margin-top: -185px;
    font-size: 1em;
  }

  .name > h2 {
    color: whitesmoke;
    margin-top: 100px;
  }

  .dino {
    margin-top: 50px;
    width: 80%;
  }
}