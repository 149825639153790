
div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  font-family: dapifer, sans-serif;
  font-size: 1.4em;
  letter-spacing: .2ch;
  color:  #a84444;
  text-shadow: 1px 1px  #181819;
}

h2 {
  font-family: luminance, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2em;
  color: #181819;
}

h3 {
  font-family: luminance, sans-serif;
  font-size: .6em;
  color: #181819;
}

div > img {
  width: 90%;
  max-width: 750px;
  left: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0px;
}

#title-div > h1 {
  padding: 20px;
}

@media only screen and (min-width: 768px) {
  .container {
    width: 100%;
    max-width: 1200px;
    /* margin-left: 120px; */
  }
}
